<template>
  <!--
  <v-card>
    <v-card-title>
        {{ headerTitle }}
    </v-card-title>
    <v-card-text>
      <slot name="body">
          {{ defaultBodyContent }}
      </slot>
    </v-card-text>
    <v-card-actions class="justify-end mr-2 pb-4">
        <template v-if="footerSubmit">
            <v-btn
                color="success"
                rounded
                small
                @click="$emit('hide')"
            >
                {{ footerSubmitTitle }}
            </v-btn>
        </template>
    </v-card-actions>
  </v-card>
  -->
  <div style="position:absolute;z-index:999;height:95%;width:98%;background-color:black; opacity:0.95;overflow:scroll;" @mouseleave="$emit('hide')">
      <div style="font-weight:bold;">{{ headerTitle }}</div>
      <div style="padding:5px;font-size:1.2rem;padding:20px 5px 0 5px">
        <table class="bizDetailTable">
          <colgroup>
            <col style="width:160px" />
            <col style="" />
          </colgroup>
          <tr>
            <td colspan="2" style="background-color:aliceblue;"><img :src="decodeURI(biz_img)" style="max-width:150px;padding:5px 3px;"></td>
          </tr>
          <tr>
            <th class="padd83">프로젝트 이름</th>
            <td class="txtL padd83">{{biz_name}}</td>
          </tr>
          <tr>
            <th>프로젝트 발주처</th>
            <td class="txtL">{{biz_order}}</td>
          </tr>
          <tr>
            <th>프로젝트 기간</th>
            <td class="txtL">{{dateTime(from_dt)}} ~ {{dateTime(to_dt)}}</td>
          </tr>
          <tr>
            <th>기반기술</th>
            <td class="txtL">{{biz_skill}}</td>
          </tr>
        </table>
      </div>
      <button @click.prevent.self="$emit('hide')" style="margin-top:20px;">
          {{ footerSubmitTitle }}
      </button>
  </div>
</template>

<script>
import moment from 'moment';

  export default {
    name: 'BizDetailDialog',
    props: {
      footerSubmit: {
        type: Boolean,
        default: true,
      },
      biz_id: {
        type: String,
        default: '-',
      },
      biz_name: {
        type: String,
        default: '-',
      },
      biz_order:{
        type: String,
        default: '-',
      },
      biz_skill:{
        type: String,
        default: '-',
      },
      from_dt:{
        type:String,
        default: '-',
      },
      to_dt:{
        type:String,
        default: '-',
      },
      biz_img: {
        type:String,
        default: '-',
      },
      footerSubmitTitle: {
        type: String,
        default: '닫기',
      },
    },
    methods: {
      dateTime: function(value) {
        return moment(value).format('YYYY-MM');
      },
    },
  }
</script>

<style scoped>

</style>