var BASE_URL = '/front';

export default {
    PAGESIZE: 10,

    //공지사항
    NOTICE : BASE_URL + '/notice',
    configureWebpack:{
        devtool: 'source-map',
    },

}