<template>
    <footer style="font-size:0.785rem;font-weight:bold;">
        <div class="footLogo" style="min-width:170px;float:left;"><img src="/img/logo_iwetec.gif" style="width:130px"></div>
        <div style="float:left;font-size:12pt;">
            <ul style="list-style: none;">
                <li style="width:100%;height:25px;">
                    <div style="width:150px;height:25px;padding:5px 5px !important;float:left;text-align:left">사업자등록번호&nbsp;&nbsp;: </div>
                    <div style="float:left;height:25px;padding:5px 5px !important;text-align:left"> 204 - 81 - 79020 </div>
                </li>
                <li style="width:100%;height:50px;">
                    <div style="min-width:150px;height:50px;padding:5px 5px !important;float:left;letter-spacing:3.4pt;text-align:left">사업장&nbsp;주소 : </div>
                    <div style="float:left;height:50px;text-align:left">서울특별시 서초구 효령로 22<br/>(방배동, 멤버스뷰 309호) </div>
                </li>
                <li style="width:100%;height:25px;">
                    <div style="min-width:150px;height:25px;padding:5px 5px !important;float:left;letter-spacing:3.5pt;text-align:left">전&nbsp;화&nbsp;번&nbsp;호 : </div>
                    <div style="float:left;height:25px;text-align:left;padding:5px 5px !important;"> 02 - 523 - 5505 </div>
                </li>
                <li style="width:100%;height:25px;">
                    <div style="min-width:150px;height:25px;padding:5px 5px !important;float:left;letter-spacing:3.5pt;text-align:left">팩&nbsp;스&nbsp;번&nbsp;호 :  </div>
                    <div style="float:left;height:25px;text-align:left;padding:5px 5px !important;">02 - 523 - 5538 </div>
                </li>
            </ul>

        </div>
        
    </footer>
</template>

<script>

export default {
    name: 'Footer'   
}
</script>
