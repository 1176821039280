<template>
    <div>
        <!--메인 이미지 -->
        <!--
        <div style="height:600px;border:1px solid #d4d4d4;background-image:url('/img/mainBg.jpg'); background-size:cover">
            <div style="height:100px;margin-top:10px;padding:5px 10px;">
                <div class="nav justify-content-end" >
                    <router-link to="/intraHome"  class="nav-item" style="color:white">intranet</router-link>
                </div>
            </div>
            <notice-list></notice-list>
            
        </div>
        -->
        <!--contents -->

        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                <a class="navbar-brand" href="/"><img src="/img/logo_iwetec.gif"/></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <!--
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="/">HOME</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                COMPANY
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="#">회사소개</a></li>
                                <li><a class="dropdown-item" href="#">CEO 인사말</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                BUSINESS
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="#">사업영역</a></li>
                                <li><a class="dropdown-item" href="#">사업실적</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="/info/infoMain">INFO</a>
                        </li>
                        -->
                    </ul>
                    <form class="d-flex">
                      <router-link to="/intraHome"><button class="btn btn-outline-success me-2">Intranet</button></router-link>
                    </form>
                </div>
            </div>
        </nav>
        <div id="mainView">
            <p  style="position:absolute; left:40px;top:180px;line-height:33pt; text-align:left;">
                    <b id="cp1" style="font-size:3.534rem; color:#E6E6FA;"> [ Total IT Solution Provider ]</b><br><br/>
                    <b id="cp2" style="font-size:2.238rem; color:#DC143C">"나(I)와 우리(WE)가 만들어가는 회사" 아이위텍 입니다.</b>
            </p>
            <p id="pr" style="position:absolute; left:60px;top:380px;text-align:left;color:#DCDCDC;font-size:1.238rem;">
                과감한 투자와 지속적인 연구개발로<br/>
                고객의 정보생활을 책임지는 든든한 동반자가 되겠습니다.
            </p>
        </div>
        <div id="mainContents">
            <div class="row">
                <div class="col-lg-6" style="border:1px solid white;">
                   <notice-list></notice-list>
                </div>
                <div class="col-lg-6" style="border:1px solid white;">
                    <recruit-list></recruit-list>
                </div>
            </div>
        </div>
        
        <div id="introduce">
            <div class="row">
                <div class="col-sm-12" style="font-size:2.2rem;color:white;padding:25px 0 10px 0"> VISION </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-3" style="padding:30px 15px 15px 15px;background-color:rgba(255, 228, 181,0.5);font-size:1.6rem;font-weight:bold;margin:0 8px; ">
                    WITH CUSTOMER
                    <br/><br/>
                    <q style="text-align:left;">고객과 더블어 WIN-WIN하는 기업으로서 고객의 경쟁력 확보를 위해 노력</q>
                </div>
                <div class="col-md-3" style="padding:30px;background-color:rgba(173, 216, 230,0.5);font-size:1.6rem;font-weight:bold;margin:0 8px; ">
                    ESG
                    <br/><br/>
                    <q style="text-align:left;">IT 기술로 실현하는 ESG 경영</q>
                </div>
                <div class="col-md-3" style="padding:30px;background-color:rgba(135, 206, 250,0.5);font-size:1.6rem;font-weight:bold;margin:0 8px; ">
                    CREATIVE
                    <br/><br/>
                    <q style="text-align:left;">시장의 흐름에 유연한 핵심기술 개발과 새로운 부가가치를 창조</q>
                </div>
            </div>
        </div>

        <div id="bizPerformance">
            <div class="row">
               
                <div class="col-sm-12">
                    <biz-performance-list></biz-performance-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import NoticeList from './NoticeList.vue';
import BizPerformanceList from './main/BizPerformanceList.vue';
import RecruitList from './main/RecruitList.vue';
//import Vision from './main/Vision.vue';
//import BizArea from './main/BizArea.vue';

export default {
    name: 'Main',
    components : { NoticeList, BizPerformanceList, RecruitList, },
    data() {
        return{
            height: 0
        }
    },
    mounted() {
        // console.log("ready...");
        window.addEventListener('resize', this.handleResize);
	},
    methods: {
        handleResize() {
            this.height = window.innerHeight;
            console.log(this.height);
        }
    }
}
</script>
