<template>
    <div>
      <head>
        <title>IWETec</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
      </head>
      
      <router-view />
      <Footer></Footer>
    </div>
</template>

<style lang="scss">
@import url("../public/css/iwe.css");
@import 'bootstrap/scss/bootstrap';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<script type="text/javascript">
//import NoticeList from './components/NoticeList.vue';
import Footer from './components/Footer.vue'; 
//import Header from './components/header.vue';
//import Intranet from './components/Intranet.vue';
//import Main from './components/Main.vue'

export default {
  name : 'app',
  components : {Footer}
}
</script>

