export default {
    OPEN_NOTICE_LIST: 'openNoticeList',
    OPEN_BIZ_PERFORMANCE_LIST: 'openBizPerformanceList',
    OPEN_RECRUIT_LIST: 'openRecruitList',
    
    BOARD_NOTICE : 'B01',
    BOARD_FREE : 'B10',
    BOARD_ADD : "boardAdd",
    BOARD_DELETE : "boardDelete",
    BOARD_SEARCH : "boardSearch",
    BOARD_DETAIL : "boardDetail",
    BOARD_MODIFY : "biardModify",
    BOARD_EMPTY : "boardEmpty",
    NOTICE_LIST : "noticeList",
    FREE_LIST : "freeList",
    FREE_DETAIL : "freeDetail",
    FREE_MODIFY : "freeModify",
    FREE_DELETE : "freeDelete",
    PERFORMANCE_LIST : "performanceList",
    RECRUIT_LIST : "recruitList",

    USER_LIST : "userList",
    USER_SEARCH : "userSearch",
    USER_DETAIL : "userDetail",
    USER_MODIFY : "userModify",
    USER_ADD : "userAdd",
    USER_EMPTY : "userEmpty",
    USER_DELETE : "userDelete",

    BIZ_LIST : "bizList",
    BIZ_SEARCH : "bizSearch",
    BIZ_FORM : "bizForm",
    BIZ_DETAIL : "bizDetail",
    BIZ_MODIFY : "bizModify",
    BIZ_ADD : "bizAdd",
    BIZ_EMPTY: "bizEmpty",
    BIZ_DELETE : "bizDelete",

    BASE_URL : "/backend",
    LOGIN: "login",
    LOGOUT: "logout",
    UID: "uid",
    IS_AUTH: "AUTH",
    LOGIN_CHECK: "noLogin"
}