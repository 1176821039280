<template>
  <!--
  <v-card>
    <v-card-title>
        {{ headerTitle }}
    </v-card-title>
    <v-card-text>
      <slot name="body">
          {{ defaultBodyContent }}
      </slot>
    </v-card-text>
    <v-card-actions class="justify-end mr-2 pb-4">
        <template v-if="footerSubmit">
            <v-btn
                color="success"
                rounded
                small
                @click="$emit('hide')"
            >
                {{ footerSubmitTitle }}
            </v-btn>
        </template>
    </v-card-actions>
  </v-card>
  -->
  <div style="position:absolute;z-index:999;height:93%;width:98%;background-color:black;word-wrap:break-word !important; overflow-y:scroll;overflow-wrap:break-word;table-layout: fixed;" @mouseleave="$emit('hide')">
      <div style="font-weight:bold;font-size:1.3rem;">{{ headerTitle }}</div>
      <pre style="padding:3px;text-align:left;font-size:1.123rem;word-break:break-all !important;overflow-wrap:break-word;">
           <slot name="body" style="word-wrap:break-word !important;overflow-wrap:break-word;">
            {{ mainContents }}
          </slot>
       </pre>
      <button @click.prevent.self="$emit('hide')">
          {{ footerSubmitTitle }}
      </button>
  </div>
</template>

<script>
  export default {
    name: 'DetailDialog',
    props: {
      footerSubmit: {
        type: Boolean,
        default: true,
      },
      headerTitle: {
        type: String,
        default: '제목',
      },
      mainContents: {
        type: String,
        default: '-',
      },
      footerSubmitTitle: {
        type: String,
        default: '닫기',
      },
    },
  }
</script>

<style scoped>

</style>