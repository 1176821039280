import { createStore } from 'vuex';
//import { createApp } from 'vue';
//import Vuex from 'vuex';
import Constant from '../Constant';
import axios from 'axios';
import router from '../router';
import CONF from "../Config";


export default createStore({
//const store = new Vuex.Store({f
  state: {
    mode: 'LIST',
    pageIndex: 1,
    //boardId: '',
    bulletin: {
      uid: '',
      board_id: CONF.BOARD_NOTICE,
      ntt_id: 0,
      subject: '',
      contents: '',
      contents_plain: '',
      read_cnt: 0,
      files: null,
      upt_id: '',
      reg_dt: '',
      upt_dt: ''
    },
    bulletin_empty: {
      uid: '',
      board_id: CONF.BOARD_NOTICE,
      ntt_id: 0,
      subject: '',
      contents: '',
      contents_plain: '',
      read_cnt: 0,
      files: null,
      upt_id: '',
      reg_dt: '',
      upt_dt: ''
    },
    noticeList: {
      pageNo: 1, pageSize: CONF.PAGESIZE, totalCount:0, noticeList: []
    },
    freeList: {
      pageNo: 1, pageSize: CONF.PAGESIZE, totalCount:0, noticeList: []
    },
    performanceList: {
      error: false, pageNo: 1, pageSize: CONF.PAGESIZE, totalCount:0, performanceList: []
    },
    recruitList: {
      pageNo: 1, pageSize: CONF.PAGESIZE, totalCount:0, recruitList: []
    },
    employee: {
      emp_no: '',
      user_id: '',
      user_pw: '',
      user_name: '',
      user_emame: '',
      user_grant: '',
      dept_id: '', 
      posi_id: '', 
      e_mail:'' ,
      tel_no:'', 
      mobile_tel_no: '', 
      zip_code:'', 
      addr1: '', 
      addr2: '',
      enter_dt:'',
      resign_dt:'',
      day_off:'',
      use_off:'', 
      reg_id: '', 
      reg_dt: '', 
      upt_id: '', 
      upt_dt: '',
      use_yn: '',
      use_yn_name: ''
    },
    employee_empty: {
      emp_no: '',
      user_id: '',
      user_pw: '',
      user_pw_chk:'',
      user_name: '',
      user_emame: '',
      user_grant: '',
      dept_id: '', 
      posi_id: '', 
      e_mail:'' , 
      tel_no:'',
      mobile_tel_no: '', 
      zip_code:'', 
      addr1: '', 
      addr2: '',
      enter_dt:'',
      day_off:'', 
      reg_id: '', 
      reg_dt: '', 
      upt_id: '', 
      upt_dt: '',
      use_yn: 't',
      use_yn_name: ''
    },
    performance: {
      biz_id: '',
      biz_order: '',
      biz_customer: '',
      biz_name: '',
      from_dt: '',
      to_dt: '',
      biz_skill: '',
      reg_id: '',
      upt_id: '',
      reg_dt: '',
      upt_dt: '',
      biz_code: '',
      open_yn: '',
      open_yn_name: '',
      use_yn: '',
      use_yn_name: '',
      img: '',
      memo: ''
    },
    performance_empty: {
      biz_id: '',
      biz_order: '',
      biz_customer: '',
      biz_name: '',
      from_dt: '',
      to_dt: '',
      biz_skill: '',
      reg_id: '',
      upt_id: '',
      reg_dt: '',
      upt_dt: '',
      biz_code: '',
      open_yn: 't',
      open_yn_name: '',
      use_yn: 't',
      use_yn_name: '',
      img: '',
      memo: ''
    },
    userList: {
      error: false, result_code:'0', result_msg:'', pageNo: 1, pageSize: CONF.PAGESIZE, totalCount:0, userList: []
    },
    uid: 'UID',
    isAuth: '',
    result: null
  },
  mutations: {
    [Constant.NOTICE_LIST] : (state, payload) => {
      console.log("mutations::NOTICE_LIST::" + payload);
      //state.noticeList = payload.noticeList;
      state.noticeList = payload.noticeList;
      /*
      console.log("mut:state:noticeList:" + state.noticeList.length);
      for(var i =0 ; i<state.noticeList.length; i++){
        var not = state.noticeList[i];
        console.log("mut:noticeList:" + not);
        console.log("mut:noticeList:" + not.ntt_id);
      }
      */
    },
    [Constant.FREE_LIST] : (state, payload) => {
      console.log("mutations::FREE_LIST::" + payload);
      //state.noticeList = payload.noticeList;
      state.freeList = payload.noticeList;
      /*
      console.log("mut:state:noticeList:" + state.noticeList.length);
      for(var i =0 ; i<state.noticeList.length; i++){
        var not = state.noticeList[i];
        console.log("mut:noticeList:" + not);
        console.log("mut:noticeList:" + not.ntt_id);
      }
      */
    },
    [Constant.PERFORMANCE_LIST] : (state, payload) => {
      console.log("mutations::PERFORMANCE_LIST::" + payload);
      //state.noticeList = payload.noticeList;
      state.performanceList = payload.performanceList;
      /*
      console.log("mut:state:noticeList:" + state.noticeList.length);
      for(var i =0 ; i<state.noticeList.length; i++){
        var not = state.noticeList[i];
        console.log("mut:noticeList:" + not);
        console.log("mut:noticeList:" + not.ntt_id);
      }
      */
    },
    [Constant.BIZ_LIST] : (state, payload) => {
      console.log("mutations::BIZ_LIST::" + payload);
      //state.noticeList = payload.noticeList;
      state.performanceList = payload.performanceList;
      /*
      console.log("mut:state:noticeList:" + state.noticeList.length);
      for(var i =0 ; i<state.noticeList.length; i++){
        var not = state.noticeList[i];
        console.log("mut:noticeList:" + not);
        console.log("mut:noticeList:" + not.ntt_id);
      }
      */
    },
    [Constant.BIZ_DETAIL] : (state, res) => {
      console.log(res);
      state.performance = res.performance;
      console.log("mutation::biz_detail::");
      console.log(state.performance);
    },
    [Constant.BIZ_EMPTY] : (state, res) => {
      console.log(res);
      state.performance_empty = res.performance_empty;
      console.log("mutation::empty::");
      console.log(state.performance_empty);
    },
    [Constant.RECRUIT_LIST] : (state, payload) => {
      console.log("mutations::RECRUIT_LIST::" + payload);
      //state.noticeList = payload.noticeList;
      state.recruitList = payload.recruitList;
      /*
      console.log("mut:state:noticeList:" + state.noticeList.length);
      for(var i =0 ; i<state.noticeList.length; i++){
        var not = state.noticeList[i];
        console.log("mut:noticeList:" + not);
        console.log("mut:noticeList:" + not.ntt_id);
      }
      */
    },

    [Constant.UID] : (state, data) => {
      console.log(data);
      state.uid = data.uid;
    },
    [Constant.LOGIN_CHECK] : (state, res) => {
      console.log(res);
      console.log(res['result']);
    },
    [Constant.BOARD_DETAIL] : (state, res) => {
      console.log(res);
      state.bulletin = res.bulletin;
      console.log("mutation::board_detail::");
      console.log(state.bulletin);
    },
    
    [Constant.BOARD_EMPTY] : (state, res) => {
      console.log(res);
      state.bulletin_empty = res.bulletin_empty;
      console.log("mutation::empty::");
      console.log(state.bulletin_empty);
    },
    [Constant.USER_LIST] : (state, payload) => {
      console.log("userList");
      console.log(payload);
      //state.noticeList = payload.noticeList;
      state.userList = payload.userList;
      /*
      console.log("mut:state:noticeList:" + state.noticeList.length);
      for(var i =0 ; i<state.noticeList.length; i++){
        var not = state.noticeList[i];
        console.log("mut:noticeList:" + not);
        console.log("mut:noticeList:" + not.ntt_id);
      }
      */
    },
    [Constant.USER_DETAIL] : (state, res) => {
      console.log(res);
      state.employee = res.employee;
      console.log("mutation::user_detail::");
      console.log(state.employee);
    },
    [Constant.USER_EMPTY] : (state, res) => {
      console.log(res);
      state.employee_empty = res.employee_empty;
      console.log("mutation::empty::");
      console.log(state.employee_empty);
    },
    
  },
  actions: {
    [Constant.OPEN_NOTICE_LIST] : (store, payload) => {
      console.log("action:OPEN_NOTICE_LIST:" + payload);
      axios.get(Constant.BASE_URL + "/open/noticeList/" + payload)
      .then((response) => {
        
        console.log(response.data);
        console.log(response.data.pageNo);
        store.commit(Constant.NOTICE_LIST, { noticeList: response.data});

      })
    },
    [Constant.OPEN_BIZ_PERFORMANCE_LIST] : (store, payload) => {
      console.log("action:OPEN_BIZ_PERFORMANCE_LIST:" + payload);
      axios.get(Constant.BASE_URL + "/open/biz/performance/" + payload)
      .then((response) => {
        
        console.log(response.data);
        console.log(response.data.pageNo);
        store.commit(Constant.PERFORMANCE_LIST, { performanceList: response.data});

      })
    },
    [Constant.OPEN_RECRUIT_LIST] : (store, payload) => {
      console.log("action:OPEN_RECRUIT_LIST:" + payload);
      axios.get(Constant.BASE_URL + "/open/recruitList/" + payload)
      .then((response) => {
        
        console.log(response.data);
        console.log(response.data.pageNo);
        store.commit(Constant.RECRUIT_LIST, { recruitList: response.data});

      })
    },

    [Constant.NOTICE_LIST] : (store, payload) => {
      console.log("action:NOTICE_LIST:" + payload);
      axios.get(Constant.BASE_URL + "/board/noticeList/" + payload)
      .then((response) => {
        /*console.log(response);
        console.log(response.data);
        console.log(response.data.error);
        console.log(response.data.board_list[0]);

        store.commit(Constant.NOTICE_LIST, { noticeList: response.data.board_list});*/

        console.log(response.data);
        console.log(response.data.pageNo);
        store.commit(Constant.NOTICE_LIST, { noticeList: response.data});

      })
    },
    [Constant.FREE_LIST] : (store, payload) => {
      console.log("action:FREE_LIST:" + payload);
      axios.get(Constant.BASE_URL + "/board/freeList/" + payload)
      .then((response) => {
        /*console.log(response);
        console.log(response.data);
        console.log(response.data.error);
        console.log(response.data.board_list[0]);

        store.commit(Constant.NOTICE_LIST, { noticeList: response.data.board_list});*/

        console.log(response.data);
        console.log(response.data.pageNo);
        store.commit(Constant.FREE_LIST, { noticeList: response.data});

      })
    },
    /***********************************************
     * Intranet
     ***********************************************/
    [Constant.LOGIN] : (store, payload) => {
      console.log('uid::'     + payload.uid);
      console.log('password::' + payload.password);
      console.log(Constant.BASE_URL + "/loginAction");
      
      axios.get(Constant.BASE_URL + "/loginAction", {
        params: {
          'uid': payload.uid,
          'password': payload.password
        }
       // axios.post(Constant.BASE_URL + "/loginAction", {
       //   payload
      })
      .then((response) => {
        console.log("login::" + response);
        console.log("login::" + response.data[0]);
        if(response.data[0].error){
          console.log("login: error:" + response.data[0].result_msg);
          alert(response.data[0].result_msg);
          store.commit(Constant.UID, {uid: 'UID'});
          //router.push("/error");
        }
        else{
          console.log("login: ok:" + response.data[0].result_msg);
          store.commit(Constant.UID, {uid: response.data[0].uid});
          //router.push("/intraHome");
        }
        
        
      })
      .catch(function (error) { 
        console.log(error);
      })
    },
    [Constant.LOGOUT] : (store, uid) => {
      console.log(Constant.BASE_URL + "/logout");
      
      axios.get(Constant.BASE_URL + "/logout", {
        params: {
          'uid': uid,
        }
       // axios.post(Constant.BASE_URL + "/loginAction", {
       //   payload
      })
      .then((response) => {
          console.log(response);
          alert(response.data.result_msg);
          store.commit(Constant.UID, {uid: 'UID'});
          router.push("/login");
      })
      .catch(function (error) { 
        console.log(error);
      })
    },
    [Constant.LOGIN_CHECK] : (store, uid) => {
      //console.log('uid:'     + uid);
      console.log(uid);
      axios.get(Constant.BASE_URL + "/loginCheck/" + uid)
     
      .then((response) => {
        console.log("login_check::response::");
        //console.log(JSON.parse(response.data));
        //console.log(JSON.parse(response.data));
        //console.log(JSON.stringify(response.data).result_code);
        //var result = JSON.parse(response.data);
        //console.log(result['result_code']);
        //console.log(result.result_code);
       
        console.log(response.data);
        console.log(response.data[0].result_code);
        console.log(response.data[0].error);
        
        if(response.data[0].error){
          store.commit(Constant.UID, {uid: 'UID'});
          router.push("/login");
        }
        else{
          console.log("LOGIN_CHECK:: OK");
          store.commit(Constant.UID, {uid: response.data[0].uid});
        }
       
       // console.log(response.data.result.result_code);
       // console.log(response.data.result.result_msg);
        //store.commit(Constant.LOGIN_CHECK, {res: response.data})
      })
     
    }
    ,
    [Constant.BOARD_ADD] : (store, data) => {
      console.log(...data.entries());
      console.log(data.uid);
      console.log(data.subject);

      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data"
          //"Content-Type": "application/x-www-form-urlencoded"
        }
      };

      
      axios.post(Constant.BASE_URL + "/board/boardAdd", data, axiosConfig)
      //axios.post("http://localhost:9011/backend/boardAdd", boardVO)
      .then((response) => {
          console.log(response);

          if(response.data.error){
            alert(response.data.result_msg);
          }
          else{
              console.log("BOARD_ADD:: OK");
              alert(response.data.result_msg);
              store.commit(Constant.BOARD_EMPTY, { bulletin_empty: response.data.bulletin_empty});
              if(response.data.board_id == "B10"){
                router.push("/intranet/free");
              }
              else{
                router.push("/intraHome");
              }
              
          }
      });
    }
    ,
    /* 2022.07.24. 정상코드
    [Constant.BOARD_ADD] : (store, data) => {
        //console.log('uid:'     + uid);
        console.log(data.bulletin);
        console.log("BOARD_ADD:" + data.bulletin.uid);
        console.log("BOARD_ADD:" + data.bulletin.upt_id);
        console.log("BOARD_ADD:" + data.bulletin.subject);

        console.log(Constant.BASE_URL + "/boardAdd/" + data.bulletin);

        
        const axiosConfig = {
          headers: {
            //"Content-Type": "multipart/form-data"
            "Content-Type": "application/x-www-form-urlencoded"
          }
        };
        //const headers = {
        //  'Content-Type': undefined
        //};

        console.log(JSON.stringify(data.bulletin));

      // const boardForm = new FormData();
      // boardForm.append("boardVO", JSON.stringify(data.bulletin));
      // boardForm.append("str", "freeeeee!!!");

      const params = new URLSearchParams();
      params.append("mode", data.mode);
      params.append("board_id", data.board_id);
      params.append("uid", data.uid);
      params.append("upt_id", data.uid);
      params.append("subject", data.bulletin.subject);
      params.append("contents", data.bulletin.contents);
      

        axios.post(Constant.BASE_URL + "/boardAdd", params, axiosConfig)
        //axios.post("http://localhost:9011/backend/boardAdd", boardVO)
        .then((response) => {
          console.log("BOARD_ADD::response::");
          //console.log(JSON.parse(response.data));
          //console.log(JSON.parse(response.data));
          //console.log(JSON.stringify(response.data).result_code);
          //var result = JSON.parse(response.data);
          //console.log(result['result_code']);
          //console.log(result.result_code);
        
          console.log(response.data);
          console.log(response.data.result_code);
          console.log(response.data.error);
          
          if(response.data.error){
            alert(response.data.result_msg);
          }
          else{
              console.log("BOARD_ADD:: OK");
              //store.commit(Constant.UID, {uid: response.data[0].uid});
              router.push("/intraHome");
          }
        
        // console.log(response.data.result.result_code);
        // console.log(response.data.result.result_msg);
          //store.commit(Constant.LOGIN_CHECK, {res: response.data})
        });
      
     
    },
    */
    [Constant.BOARD_SEARCH] : (store, data) => {
      console.log(data.boardId);
      console.log(data.searchType);
      console.log(data.searchValue);

      axios.get(Constant.BASE_URL + "/board/noticeList/" + data.boardId + "/" + data.searchType + "/" + data.searchValue)
        .then((response) => {
          console.log(response.data);
          if(response.data.error){
            alert(response.data.result_msg);
          }
          else{
            store.commit(Constant.NOTICE_LIST, { noticeList: response.data});
          }
          
        });
    },
    [Constant.BOARD_DETAIL] : (store, data) => {
     
      console.log("Constant.BOARD_DETAIL::" + data.nttId);
      
      axios.get(Constant.BASE_URL + "/board/boardDetail/" + data.nttId )
        .then((response) => {
          console.log(response.data);
          if(response.data.error){
            alert(response.data.result_msg);
          }
          else{
            store.commit(Constant.BOARD_DETAIL, { bulletin: response.data.board_detail});
            router.push("/intranet/boardDetail");
          }
          
        });
      
    },
    [Constant.FREE_DETAIL] : (store, data) => {
     
      console.log("Constant.FREE_DETAIL::" + data.nttId);
      
      axios.get(Constant.BASE_URL + "/board/boardDetail/" + data.nttId )
        .then((response) => {
          console.log(response.data);
          if(response.data.error){
            alert(response.data.result_msg);
          }
          else{
            store.commit(Constant.BOARD_DETAIL, { bulletin: response.data.board_detail});
            router.push("/intranet/freeDetail");
          }
        });
    },
    [Constant.BOARD_MODIFY] : (store, res) => {
      console.log(res);
      console.log("mutation::board_modify::");
      router.push("/intranet/boardModify");
    },
    [Constant.FREE_MODIFY] : (store, res) => {
      console.log(res);
      console.log("mutation::free_modify::");
      router.push("/intranet/freeModify");
    },
    [Constant.BOARD_DELETE] : (store, data) => {

      console.log("board_delete");
      console.log(data.nttId);

      axios.post(Constant.BASE_URL + "/board/boardDelete/", null, { params: {
                  
        ntt_id : data.nttId,
    
      }})
        .then((response) => {
          console.log(response.data);
          
          if(response.data.error){
            alert(response.data.result_msg);
          }
          else{
            alert(response.data.result_msg);
            //store.commit(Constant.BOARD_DETAIL, { bulletin: response.data.board_detail});
            router.push("/intraHome");
          }
        });
    }
    ,
    [Constant.FREE_DELETE] : (store, data) => {

      console.log("free_delete");
      console.log(data.nttId);

      axios.post(Constant.BASE_URL + "/board/boardDelete/", null, { params: {
                  
        ntt_id : data.nttId,
    
      }})
        .then((response) => {
          console.log(response.data);
          

          if(response.data.error){
            alert(response.data.result_msg);
          }
          else{
            alert(response.data.result_msg);
            //store.commit(Constant.BOARD_DETAIL, { bulletin: response.data.board_detail});
            router.push("/intranet/free");
          }
        });
    }
    ,
    [Constant.USER_LIST] : (store, pageIndex) => {
     
      console.log("Constant.USER_LIST::");
      
      axios.get(Constant.BASE_URL + "/user/userList/" + pageIndex )
        .then((response) => {
          console.log(response.data);
          store.commit(Constant.USER_LIST, { userList: response.data});
          //router.push("/intranet/boardDetail");
        });
      
    },
    [Constant.USER_SEARCH] : (store, data) => {
      console.log(data.boardId);
      console.log(data.searchType);
      console.log(data.searchValue);

      axios.get(Constant.BASE_URL + "/user/userList/" + data.searchType + "/" + data.searchValue)
        .then((response) => {
          console.log(response.data);
          if(response.data.error){
            alert(response.data.result_msg);
          }
          else{
            store.commit(Constant.USER_LIST, { userList: response.data});
          }
          
        });
    },
    [Constant.USER_DETAIL] : (store, data) => {
     
      console.log("Constant.USER_DETAIL::" + data.userId);
      
      axios.get(Constant.BASE_URL + "/user/userDetail/" + data.userId )
        .then((response) => {
          console.log(response.data);

          if(response.data.error){
            alert(response.data.result_msg);
          }
          else{
            store.commit(Constant.USER_DETAIL, { employee: response.data.user_detail});
            router.push("/intranet/userDetail");
          }
        });
    },
    [Constant.USER_ADD] : (store, data) => {
      console.log(...data.entries());
      console.log(data.uid);
      console.log(data.user_id);

      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data"
          //"Content-Type": "application/x-www-form-urlencoded"
        }
      };

      
      axios.post(Constant.BASE_URL + "/user/userAdd", data, axiosConfig)
      //axios.post("http://localhost:9011/backend/boardAdd", boardVO)
      .then((response) => {
          console.log(response);

          if(response.data.error){
            alert(response.data.result_msg);
          }
          else{
              console.log("USER_ADD:: OK");
              alert(response.data.result_msg);
              store.commit(Constant.USER_EMPTY, { employee_empty: response.data.employee_empty});
              router.push("/intranet/userList");
          }
      });
    },
    [Constant.USER_MODIFY] : (store, res) => {
      console.log(res);
      console.log("mutation::user_modify::");
     
      router.push("/intranet/userModify");
      
    },
    [Constant.USER_DELETE] : (store, data) => {

      console.log("user_delete");
      console.log(data.nttId);

      axios.post(Constant.BASE_URL + "/user/userDelete/", null, { params: {
                  
        user_id : data.userId,
    
      }})
        .then((response) => {
          console.log(response.data);
          
          //store.commit(Constant.BOARD_DETAIL, { bulletin: response.data.board_detail});
          if(response.data.error){
            alert(response.data.result_msg);  
          }
          else{
            alert(response.data.result_msg);
            router.push("/intranet/userList");
          }
          
        });
    },
    [Constant.BIZ_LIST] : (store, pageIndex) => {
     
      console.log("Constant.BIZ_LIST::");
      
      axios.get(Constant.BASE_URL + "/biz/bizList/" + pageIndex )
        .then((response) => {
          console.log(response.data);
          store.commit(Constant.BIZ_LIST, { performanceList: response.data});
          //router.push("/intranet/boardDetail");
        });
      
    },
    [Constant.BIZ_SEARCH] : (store, data) => {
      console.log(data.bizdId);
      console.log(data.searchType);
      console.log(data.searchValue);

      axios.get(Constant.BASE_URL + "/biz/bizSearch/" + data.searchType + "/" + data.searchValue)
        .then((response) => {
          console.log(response.data);
          store.commit(Constant.BIZ_LIST, { performanceList: response.data});
        });
    },
    [Constant.BIZ_FORM] : (store, data) => {
      console.log(data.bizdId);
      console.log(data.searchType);
      console.log(data.searchValue);

      axios.get(Constant.BASE_URL + "/grantCheck/" + store.uid )
        .then((response) => {
          console.log(response.data);

          if(response.data.error){
            console.log(response.data.result_msg);
          }
          else{
            router.push({name: 'bizForm', params: { bizMode: 'ADD' }});
          }
        });
    },
    [Constant.BIZ_DETAIL] : (store, data) => {
     
      console.log("Constant.BIZ_DETAIL::" + data.bizId);
      
      axios.get(Constant.BASE_URL + "/biz/bizDetail/" + data.bizId )
        .then((response) => {
          console.log(response.data);

          if(response.data.error){
            alert(response.data.result_msg);
          }
          else{
            store.commit(Constant.BIZ_DETAIL, { performance: response.data.biz_detail});
            router.push("/intranet/bizDetail");
          }
        });
    },
    [Constant.BIZ_ADD] : (store, data) => {
      console.log(...data.entries());
      console.log(data.uid);
      console.log(data.biz_name);

      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data"
          //"Content-Type": "application/x-www-form-urlencoded"
        }
      };

      
      axios.post(Constant.BASE_URL + "/biz/bizAdd", data, axiosConfig)
      //axios.post("http://localhost:9011/backend/boardAdd", boardVO)
      .then((response) => {
          console.log(response);

          if(response.data.error){
            alert(response.data.result_msg);
          }
          else{
              console.log("BIZ_ADD:: OK");
              //store.commit(Constant.UID, {uid: response.data[0].uid});
              alert(response.data.result_msg);
              store.commit(Constant.BIZ_EMPTY, { performance_empty: response.data.performance_empty});
              router.push("/intranet/bizList");
          }
      });
    },
    [Constant.BIZ_MODIFY] : (store, res) => {
      console.log(res);
      console.log("mutation::biz_modify::");
      router.push("/intranet/bizModify");
    },
    [Constant.BIZ_DELETE] : (store, data) => {

      console.log("biz_delete");
      console.log(data.bizId);

      axios.post(Constant.BASE_URL + "/biz/bizDelete/", null, { params: {
                  
        biz_id : data.bizId,
    
      }})
        .then((response) => {
          console.log(response.data);
          //store.commit(Constant.BOARD_DETAIL, { bulletin: response.data.board_detail});

          if(response.data.error){
            alert(response.data.result_msg);
          }
          else{
            alert(response.data.result_msg);
            router.push("/intranet/bizList");
          }
        });
    },
    
  },
  
  modules: {
  },

  getter:{
    getUid: state => state.uid,
    getIsAuth: state => state.isAuth
  }
});

//export default store;

