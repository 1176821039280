<style scoped>

</style>
<template>
    <div class="subContents" @mouseenter="inFocusAaction" @mouseleave="outFocusAction" :class="{inFocus: inFocus, outFocus: outFocus}" style="z-index:10;font-size:1.1rem">
        <detail-dialog v-if="showDetail" :headerTitle="headerTitle" :mainContents="mainContents" @hide="hideDialog" >

        </detail-dialog>
        <div class="mainSubT">
            <p> [ 공지사항 ] </p>
        </div>
       
        <ul v-if="noticeList.totalCount > 0"> 
            <li class="liOpenNotice" v-for="notice in noticeList.noticeList" :key="notice.ntt_id" 
                @mouseenter="inActive" @mouseleave="outActive" :class="{isActive: isActive}"
                @click="showModal(notice)">
                {{notice.subject}}
            </li>
        </ul>
        <ul v-else style="">
            <li style="list-style:none;text-align:left;margin-left:-8px;font-size:1.1rem;"> - 등록된 공지사항이 없습니다.</li>
        </ul>
        
        <!--
        <v-dialog v-model="showDetail">
            <detail-dialog header-title="header"  @hide="hideDialog">
                <template v-slot:body>
                    <v-text-field placeholder="내용을 입력하세요"></v-text-field>
                </template>
            </detail-dialog>
        </v-dialog>
        -->
    </div>
</template>

<script type="text/javascript">
import Constant from '../Constant';
import { mapState } from 'vuex';
import DetailDialog from './main/DetailDialog.vue';
export default {
    name : 'notice-list',
    computed: mapState([ 'noticeList' ]),
    components: { DetailDialog },
    data : function() {
        return {
            inFocus : false,
            outFocus: true,
            isActive: false,

            showDetail: false,

            headerTitle: '',
            mainContents: '-',
        }
    },
    created:  function(){
        this.$store.dispatch(Constant.OPEN_NOTICE_LIST, 1);
    },
    methods: {
         inFocusAaction: function(){
             console.log(this);
             console.log('inFocus');
             this.inFocus = true;
             this.outFocus = false;
         },
         outFocusAction: function(){
             console.log(this);
             console.log('outFocus');
            this.inFocus = false;
            this.outFocus = true;
            
         },
         inActive: function(){
            this.isActive =  true;
         },
         outActive: function(){
            this.isActive = false;
         },
         viewDetailNotice: function(){ 

         },
         showModal: function(notice) {

             console.log(notice);

             //데이터 세팅
             //공지사항 리스트에서 공지사항 상세내용 선택
            this.headerTitle = notice.subject;
            this.mainContents = notice.contents;

            this.showDetail = true;

         },
         hideModal: function() {

             console.log('mouse out');
             
            this.headerTitle = '';

            this.showDetail = false;

         },
         hideDialog: function(){
             this.headerTitle = '';

             this.showDetail = false;
         }
    }
}
</script>