import { createRouter, createWebHistory } from 'vue-router'
import Main from '../components/Main.vue'

//import Intranet from '../components/Intranet.vue'

const routes = [
  {
    path: '/',
    name: '',
    component: Main
  },
  {
    path: '/intranet',
    name: 'intranet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Intranet.vue')
  },
  {
    path: '/intraHome',
    name: 'intraHome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/Notice.vue') 
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Login.vue') 
  },
  {
    path: '/intranet/notice',
    name: 'intraNotice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/Notice.vue') 
  },
  {
    path: '/intranet/free',
    name: 'intraFree',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/Free.vue') 
  },
  {
    path: '/intranet/freeDetail',
    name: 'freeDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/FreeDetail.vue') 
  },
  {
    path: '/intranet/boardForm/:boardId/:boardMode',
    name: 'boardForm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/BoardForm.vue'),
    props:true
  },
  {
    path: '/intranet/freeForm/:boardId/:boardMode',
    name: 'freeForm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/FreeForm.vue'),
    props:true
  },
  {
    path: '/intranet/boardDetail',
    name: 'boardDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/BoardDetail.vue'),
    props:true
  },
  {
    path: '/intranet/boardModify',
    name: 'boardModify',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/BoardModify.vue'),
    props:true
  },
  {
    path: '/intranet/freeModify',
    name: 'freeModify',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/FreeModify.vue') 
  },
  {
    path: '/intranet/userList',
    name: 'userList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/UserList.vue'),
    props:true
  },
  {
    path: '/intranet/userForm/:userMode',
    name: 'userForm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/UserForm.vue'),
    props:true
  },
  {
    path: '/intranet/userDetail',
    name: 'userDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/UserDetail.vue'),
    props:true
  },
  {
    path: '/intranet/userModify',
    name: 'userModify',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/UserModify.vue'),
    props:true
  },
  {
    path: '/intranet/bizList',
    name: 'bizList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/BizList.vue'),
    props:true
  },
  {
    path: '/intranet/bizDetail',
    name: 'bizDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/BizDetail.vue'),
    props:true
  },
  {
    path: '/intranet/bizForm/:bizMode',
    name: 'bizForm', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/BizForm.vue'),
    props:true
  },
  {
    path: '/intranet/bizModify',
    name: 'bizModify', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/intranet/BizModify.vue'),
    props:true
  },
  {
    path: '/info/infoMain',
    name: 'infoMain',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/info/InfoMain.vue'),
    props:true
  },
  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
