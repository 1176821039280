<style scoped>
.li-hover:hover{ color:cornflowerblue !important; font-weight:bold;}
</style>
<template>
    <div class="subContents3" @mouseenter="inFocusAaction" @mouseleave="outFocusAction" :class="{inFocus: inFocus, outFocus: outFocus}">
        <detail-dialog v-if="showDetail" 
                        :biz_id="biz_id" 
                        :biz_name="biz_name" 
                        :biz_order="biz_order"
                        :biz_skill="biz_skill"
                        :from_dt="from_dt"
                        :to_dt="to_dt"
                        :biz_img="biz_img"
                        @hide="hideModal" >

        </detail-dialog>
        <div class="mainSubT2 padd2030">
            <p>  사업실적  </p>
        </div>
        <ul v-if="performanceList.totalCount > 0" style="color:white;padding:0 5px 5px 25px;"> 
            <li v-for="performance in performanceList.performanceList" :key="performance.biz_id" style="text-align:left;font-size:1.12rem;" class="li-hover"
                @mouseover="inActive" 
                @mouseout="outActive" 
                @click.self="showModal(performance)">
                {{performance.biz_name}}
            </li>
        </ul>
        <ul v-else style="color:white;">
            <li style="list-style:none;text-align:left;margin-left:-8px;"> - 등록된 사업실적이 없습니다.</li>
        </ul>
        
    </div>
</template>

<script type="text/javascript">
import Constant from '../../Constant'
import { mapState } from 'vuex'; 
import DetailDialog from './BizDetailDialog.vue';
export default {
    name : 'biz-performance-list',
    components: { DetailDialog },
    computed: mapState([ 'performanceList' ]),
    created:  function(){
        this.$store.dispatch(Constant.OPEN_BIZ_PERFORMANCE_LIST, 1);
    },
    data : function() {
        return {
            inFocus : false,
            outFocus: true,
            isActive: false,

            showDetail: false,

            biz_id: '-',
            biz_name: '-',
            biz_order: '-',
            biz_skill: '-',
            from_dt: '-',
            to_dt: '-',
            biz_img: '-',
        }
    },
    methods: {
         inFocusAaction: function(){
             console.log(this);
             console.log('inFocus');
             this.inFocus = true;
             this.outFocus = false;
         },
         outFocusAction: function(){
             console.log(this);
             console.log('outFocus');
            this.inFocus = false;
            this.outFocus = true;
            
         },
         inActive: function(){
            this.isActive =  true;
         },
         outActive: function(){
            this.isActive = false;
         },
         showModal: function(biz) {

             console.log(biz);
             console.log(biz.img);

             //데이터 세팅
             //사업 리스트에서 사업 상세내용 선택
            this.biz_id = biz.biz_id;
            this.biz_name = biz.biz_name;
            this.biz_order = biz.biz_order;
            this.biz_skill = biz.biz_skill;
            this.from_dt = biz.from_dt;
            this.to_dt = biz.to_dt;
            this.biz_img = '/img/biz/'+biz.img;

            this.showDetail = true;

         },
         hideModal: function() {

             
            this.headerTitle = '';

            this.showDetail = false;

         },
        
    }
}
</script>