import { createApp } from 'vue'
//import Vue from 'vue'

import App from './App.vue'
import VueRouter from './router'
import store from './store'
//import axios from 'axios'
import ES6Promise from 'es6-promise'
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'




ES6Promise.polyfill();


//VueElement.prototype.$axios = axios;
//Vue.config.productionsTip = false;


//new Vue({
//   store,
//  render: h => h(App)
//}).$mount('#app');



createApp(App).use(store).use(VueRouter).use(BootstrapVue3).mount('#app')

//const app = createApp(App);
//app.mount('#app');
//app.config.productionsTip = false;
//app.use(store);


