<style scoped>

</style>
<template>
    <div class="subContents" @mouseover="inFocusAaction" @mouseout="outFocusAction" :class="{inFocus: inFocus, outFocus: outFocus}">
        <div class="mainSubT">
            <p> [ 채용공고 ] </p>
        </div>
        <ul v-if="recruitList.totalCount > 0" style="color:white;font-size:1.1rem"> 
            <li v-for="recruit in recruitList.recruitList" :key="recruit.ntt_id">{{recruit.subject}}</li>
        </ul>
        <ul v-else style="color:white;font-size:1.1rem;">
            <li style="list-style:none;text-align:left;margin-left:-8px;"> - 등록된 채용공고가 없습니다. </li>
        </ul>
        
    </div>
</template>

<script type="text/javascript">
import Constant from '../../Constant'
import { mapState } from 'vuex'; 
export default {
    name : 'recruit-list',
    computed: mapState([ 'recruitList' ]),
    data : function() {
        return {
            inFocus : false,
            outFocus: true,
            isActive: false,

            showDetail: false,

            headerTitle: '',
        }
    },
    created:  function(){
        this.$store.dispatch(Constant.OPEN_RECRUIT_LIST, 1);
    },
    methods: {
         inFocusAaction: function(){
             
             this.inFocus = true;
             this.outFocus = false;
         },
         outFocusAction: function(){
            this.inFocus = false;
            this.outFocus = true;
         },
         inActive: function(){
            this.isActive =  true;
         },
         outActive: function(){
            this.isActive = false;
         },
         viewDetailNotice: function(){ 

         },
         showModal: function(notice) {

             console.log(notice);

             //데이터 세팅
             //공지사항 리스트에서 공지사항 상세내용 선택
            this.headerTitle = notice.subject;

            this.showDetail = true;

         },
         hideDialog: function(){
             this.headerTitle = '';

             this.showDetail = false;
         }
    }
}
</script>